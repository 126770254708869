// src/App.tsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import AboutUs from './components/aboutus';
import Page from './components/Page';
import NavBar from './components/NavBar';
import './index.css'; // Global styles

const App: React.FC = () => {
  return (
    <div className="app-container">
      <NavBar />
      <div className="content">
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/page/:id" element={<Page />} />
          <Route path="/whats-new" element={<Dashboard />} />
          <Route path="/aboutus" element={<AboutUs />} />
        </Routes>
      </div>
    </div>
  );
};

export default App;
