// src/components/Page.tsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { pages, PageData } from '../config'; // Ensure this path is correct
import './Page.css'; // Import the CSS for styling

const Page: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [page, setPage] = useState<PageData | undefined>(undefined);
  const [data, setData] = useState<any>(null); // Type according to your data structure
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const currentPage = pages.find(page => page.id === id);
    if (currentPage) {
      setPage(currentPage);
      fetch(currentPage.dataUrl)
        .then(response => response.json())
        .then(data => {
          setData(data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          // Handle errors
        });
    }
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (!page) return <div>Page not found</div>;

  return (
    <div className="page-content">
      <button onClick={() => window.history.back()}>Back</button>
      <h1>{page.title}</h1>
      <img src={page.imageUrl} alt={page.title} />
      <div dangerouslySetInnerHTML={{ __html: page.shortDescription }} />
      <div dangerouslySetInnerHTML={{ __html: page.longDescription }} />
      {data && (
        <div>
          <h2>Real-Time Data</h2>
          <pre>{JSON.stringify(data, null, 2)}</pre> {/* Display data; customize as needed */}
        </div>
      )}
      
    </div>
  );
};

export default Page;
