import React from 'react';

const AboutUs: React.FC = () => {
    return (
        <div>
            <h2>About Us</h2>
            <p>
                Welcome to Meradream.com! We provide financial insights on the Indian stock market, making it easy for people to get straight to the point. 
                </p>
                <p> Our goal is to empower individuals with the knowledge they need to make informed investment decisions.
            </p>
            <p>
                If you have any questions or would like to get in touch, please feel free to contact our team. You can reach out to our contact person, 
            </p>
            <h3>Thank you</h3>
            <h3>Durgesh Yadav</h3>
            <div>
                {/* Add your Google Map component here */}
            </div>
        </div>
    );
};

export default AboutUs;