// src/components/Dashboard.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { pages } from '../config'; // Ensure this path is correct
import './Page.css'; // Import CSS for consistent styling
import './Dashboard.css'; // Import CSS for consistent styling

const Dashboard: React.FC = () => {
  return (
    <div className="dashboard-container">
      <h3 className="success">Welcome to <strong>MeraDream.com</strong> (Market ki seedhi baat..)</h3>
      <h1>Indian - Stock Market Dashboard</h1>
      <p>Welcome to your Financial Insights Dashboard! Stay ahead in the Indian stock market with our concise, data-driven updates. Track top gainers and losers, analyze sector trends, and get expert forecasts—all in one place. Empower your investment decisions with actionable insights, and confidently navigate the market with our intuitive, real-time analytics. Your journey to smarter investing starts here.</p>
      <p><strong>Click on a card to view more details.</strong></p>
      <div className="dashboard-cards">
        {pages.map(page => (
          <div key={page.id} className="dashboard-card">
            <Link to={`/page/${page.id}`} className="card-link">
              {/* <img src={page.imageUrl} alt={page.title} className="card-image" /> */}
              <div className="card-content">
                <h2>{page.title}</h2>
                <div dangerouslySetInnerHTML={{ __html: page.shortDescription }} />
                <button className="know-more-button">Know More</button>
              </div>
            </Link>
          </div>
        ))}
      </div>    
    <strong>Investment Disclaimer:</strong>
    <p>
    Investing in the stock market involves substantial risk and may not be suitable for every investor. The information provided on meradream.com is for educational and informational purposes only and does not constitute financial advice or a recommendation to buy or sell any securities. All investments carry the risk of loss, including the potential loss of principal.
    </p><p>
    Past performance is not indicative of future results, and no representation is made that any investment will achieve gains or losses similar to those described. Users of this website should perform their own research and consult with a qualified financial advisor before making any investment decisions.
    </p><p>
    The content on this site is provided "as is" without any warranties, either express or implied. meradream.com and its affiliates do not assume any responsibility for any losses or damages resulting from the use of the information provided. By using this website, you acknowledge and agree to these terms and understand the inherent risks associated with investing in the stock market.
</p>

    </div>
  );
};

export default Dashboard;