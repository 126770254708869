import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css'; 

// Get the root element from your HTML file
const container = document.getElementById('root');

// Ensure the container exists before proceeding
if (container) {
  // Create a root
  const root = createRoot(container);

  // Render the app within the root
  root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
} else {
  console.error('Root element not found');
}
