// src/components/NavBar.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { pages } from '../config'; // Ensure this path is correct
import './NavBar.css'; // Import the navbar-specific CSS

const NavBar: React.FC = () => {
  return (
    <div className="navbar">
      <Link to="/" className="dropdown-item successBackground">MeraDream.com</Link>
      <Link to="/" className="dropdown-item">Dashboard</Link>
      {/* <div className="nav-item dropdown">
        <button className="dropdown-button">
          What's New
        </button>
        <div className="dropdown-menu">
          {pages.map((page, index) => (
            <Link key={index} to={`/page/${page.id}`} className="dropdown-item">
              {page.title}
            </Link>
          ))}
        </div>
      </div> */}
      <Link to="/aboutus" className="dropdown-item">About Us</Link>
    </div>
  );
};

export default NavBar;
