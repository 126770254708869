// src/data/config.ts
export interface PageData {
    id: string;
    title: string;
    shortDescription: string;
    longDescription: string;
    imageUrl: string;
    dataUrl: string;
  }
  
 // src/data/config.ts
export const pages: PageData[] = [
    {
      id: '1',
      title: 'Last Month Stock Market',
      shortDescription: `
        <strong>Overview:</strong> A look at the stock market trends last month.
        <ul>
          <li>High volatility</li>
          <li>IT and Pharma sectors strong</li>
        </ul>
      `,
      longDescription: `
        <h2>Detailed Analysis</h2>
        <p>In the past month, the Indian stock market experienced significant fluctuations. The Nifty 50 index fluctuated between 17,500 and 18,000 points, reflecting market volatility driven by global economic conditions and domestic policy changes.</p>
        <h3>Key Highlights:</h3>
        <ul>
          <li><strong>IT Sector:</strong> Strong performance from Infosys and TCS, driven by robust quarterly earnings and increased demand for digital transformation services.</li>
          <li><strong>Pharmaceuticals:</strong> Gains in stocks like Dr. Reddy's Laboratories, supported by strong demand for generics and new drug approvals.</li>
        </ul>
        <p>Despite the volatility, the overall trend has been positive, with select sectors outperforming the broader market.</p>
        <h3>Actionable Insights:</h3>
        <p>Investors are advised to maintain positions in IT and Pharma sectors as they are likely to continue their strong performance. Caution is advised for sectors exposed to global economic fluctuations.</p>
        <h3>Forecast:</h3>
        <p>The market is expected to remain volatile in the coming weeks, but with a positive bias, especially in defensive sectors like IT and Pharma. Consider holding or selectively increasing positions in these sectors.</p>
      `,
      imageUrl: '/images/last-month.jpg',
      dataUrl: 'https://api.example.com/last-month',
    },
    {
      id: '2',
      title: 'Indian Stock Market Overview',
      shortDescription: `
        <strong>Overview:</strong> An overview of the current state of the Indian stock market.
        <ul>
          <li>Steady growth</li>
          <li>Strong performance by large caps</li>
        </ul>
      `,
      longDescription: `
        <h2>Market Overview</h2>
        <p>The Indian stock market has shown a steady growth trajectory in recent months, supported by positive macroeconomic indicators and strong corporate earnings. Both the Nifty 50 and Sensex indices have recorded gains, with large-cap stocks leading the rally.</p>
        <h3>Sector Performance:</h3>
        <ul>
          <li><strong>Large Caps:</strong> Companies like Reliance Industries and HDFC Bank have been the primary drivers of growth, benefiting from strong fundamentals and positive investor sentiment.</li>
          <li><strong>Small and Mid Caps:</strong> These segments have also shown resilience, with select stocks outperforming due to strong earnings growth and sector-specific tailwinds.</li>
        </ul>
        <h3>Actionable Insights:</h3>
        <p>Investors should focus on large-cap stocks for stability and potential steady returns. Small and mid-cap stocks can offer higher returns but come with increased risk, making them suitable for investors with a higher risk appetite.</p>
        <h3>Forecast:</h3>
        <p>The market is expected to continue its upward trajectory in the next month, with large-cap stocks providing a safer investment option. Consider adding or holding positions in top-performing large-cap stocks.</p>
      `,
      imageUrl: '/images/indian-stock-market.jpg',
      dataUrl: 'https://api.example.com/indian-stock-market',
    },
    {
      id: '3',
      title: 'Top Gainers of the Week',
      shortDescription: `
        <strong>Overview:</strong> Stocks that showed significant gains this week.
        <ul>
          <li>Focus on large-cap stocks</li>
          <li>Strong performance across various sectors</li>
        </ul>
      `,
      longDescription: `
        <h2>Weekly Gainers</h2>
        <p>This week's top gainers reflect strong performance across various sectors, indicating positive investor sentiment and confidence in these companies.</p>
        <h3>Top Gainers:</h3>
        <ul>
          <li><strong>Reliance Industries:</strong> Up by 5% due to strong earnings and expansion into new sectors like renewable energy.</li>
          <li><strong>Infosys:</strong> Rose by 4.5%, driven by strong quarterly results and positive guidance for the future.</li>
          <li><strong>HDFC Bank:</strong> Increased by 3.8%, benefiting from robust loan growth and asset quality improvement.</li>
        </ul>
        <h3>Actionable Insights:</h3>
        <p>Consider adding these stocks to your portfolio, especially if you seek exposure to companies with strong growth prospects. However, it's essential to evaluate the sustainability of these gains before making large investments.</p>
        <h3>Forecast:</h3>
        <p>These stocks are likely to continue their positive momentum in the short term, making them good candidates for both short-term and long-term investments.</p>
      `,
      imageUrl: '/images/top-gainers.jpg',
      dataUrl: 'https://api.example.com/top-gainers',
    },
    {
      id: '4',
      title: 'Top Losers of the Week',
      shortDescription: `
        <strong>Overview:</strong> Stocks that showed significant losses this week.
        <ul>
          <li>Focus on volatile sectors</li>
          <li>Impact of global economic conditions</li>
        </ul>
      `,
      longDescription: `
        <h2>Weekly Losers</h2>
        <p>This week's top losers were significantly impacted by negative news and sector-specific challenges, leading to a decline in their stock prices.</p>
        <h3>Top Losers:</h3>
        <ul>
          <li><strong>Tata Motors:</strong> Down by 6%, affected by concerns over global chip shortages and supply chain disruptions.</li>
          <li><strong>State Bank of India:</strong> Dropped by 4.2%, driven by worries over non-performing assets (NPAs) and slower loan growth.</li>
          <li><strong>Sun Pharma:</strong> Declined by 3.5%, due to regulatory challenges and weak global sales.</li>
        </ul>
        <h3>Actionable Insights:</h3>
        <p>It's advisable to avoid or reduce exposure to these stocks until the underlying issues are resolved. Consider reallocating funds to more stable sectors or stocks.</p>
        <h3>Forecast:</h3>
        <p>These stocks may continue to face pressure in the coming weeks, making them risky investments. Monitor the situation closely before considering any new positions.</p>
      `,
      imageUrl: '/images/top-losers.jpg',
      dataUrl: 'https://api.example.com/top-losers',
    },
    {
      id: '5',
      title: 'Sector Performance',
      shortDescription: `
        <strong>Overview:</strong> Performance of various sectors in the stock market.
        <ul>
          <li>IT and Pharma sectors leading</li>
          <li>Automobile sector lagging</li>
        </ul>
      `,
      longDescription: `
        <h2>Sector-wise Performance</h2>
        <p>This report highlights the performance of different sectors in the Indian stock market, focusing on those that are driving market growth and those that are facing challenges.</p>
        <h3>Key Sectors:</h3>
        <ul>
          <li><strong>IT Sector:</strong> Driven by strong global demand for technology services, the IT sector continues to lead, with companies like Infosys and TCS posting strong earnings.</li>
          <li><strong>Pharma Sector:</strong> The pharmaceutical sector remains strong due to ongoing demand for healthcare products and services, particularly in the generics and specialty drugs segments.</li>
          <li><strong>Automobile Sector:</strong> The sector is lagging due to global supply chain issues, particularly the semiconductor shortage, which has disrupted production for major players like Tata Motors and Maruti Suzuki.</li>
        </ul>
        <h3>Actionable Insights:</h3>
        <p>Investors should consider increasing their exposure to the IT and Pharma sectors, which are expected to continue performing well. It's advisable to avoid or reduce exposure to the Automobile sector until supply chain issues are resolved.</p>
        <h3>Forecast:</h3>
        <p>The IT and Pharma sectors are likely to remain strong in the next month, while the Automobile sector may continue to face headwinds. Focus on sectors with strong growth prospects and stable fundamentals.</p>
      `,
      imageUrl: '/images/sector-performance.jpg',
      dataUrl: 'https://api.example.com/sector-performance',
    },
    {
      id: '6',
      title: 'Market News Highlights',
      shortDescription: `
        <strong>Overview:</strong> Key news and events that impacted the stock market this week.
        <ul>
          <li>Policy changes</li>
          <li>Global market influences</li>
        </ul>
      `,
      longDescription: `
        <h2>Weekly Market News</h2>
        <p>This section covers the major news stories that influenced the Indian stock market this week, including policy changes, global events, and other significant developments.</p>
        <h3>Key News:</h3>
        <ul>
          <li><strong>RBI Monetary Policy:</strong> The Reserve Bank of India's decision to maintain interest rates has provided stability to the market, with positive implications for the banking and financial sectors.</li>
          <li><strong>Global Market Trends:</strong> Positive developments in the US and European markets have had a favorable impact on Indian equities, particularly in the IT and Pharma sectors.</li>
          <li><strong>Domestic Policy Changes:</strong> Recent government initiatives to boost infrastructure spending have positively impacted sectors like construction and engineering.</li>
        </ul>
        <h3>Actionable Insights:</h3>
        <p>Stay informed about policy changes and global events, as they can have a significant impact on the market. Consider adjusting your portfolio to align with sectors benefiting from these developments.</p>
        <h3>Forecast:</h3>
        <p>The market is likely to remain influenced by both domestic and global factors in the coming weeks. Investors should focus on sectors that are positively impacted by these developments.</p>
      `,
      imageUrl: '/images/market-news.jpg',
      dataUrl: 'https://api.example.com/market-news',
    },
    {
      id: '7',
      title: 'Investor Sentiment',
      shortDescription: `
        <strong>Overview:</strong> Current investor sentiment in the stock market.
        <ul>
          <li>Optimistic outlook</li>
          <li>High participation from retail investors</li>
        </ul>
      `,
      longDescription: `
        <h2>Investor Sentiment Analysis</h2>
        <p>This section provides an analysis of current investor sentiment in the Indian stock market, highlighting the factors driving optimism or caution among investors.</p>
        <h3>Sentiment Trends:</h3>
        <ul>
          <li><strong>Optimism:</strong> High levels of optimism are driven by strong corporate earnings, positive economic indicators, and robust participation from retail investors.</li>
          <li><strong>Retail Investor Participation:</strong> Retail investors are increasingly participating in the market, driven by the ease of access to trading platforms and favorable market conditions.</li>
          <li><strong>Cautious Sentiment:</strong> While overall sentiment is positive, some investors remain cautious due to global uncertainties and the potential for market corrections.</li>
        </ul>
        <h3>Actionable Insights:</h3>
        <p>Given the optimistic sentiment, it may be a good time to invest in sectors with strong fundamentals. However, it's important to remain vigilant and diversify your portfolio to mitigate risks.</p>
        <h3>Forecast:</h3>
        <p>Investor sentiment is expected to remain positive in the near term, with continued retail participation and strong market fundamentals. Consider maintaining or increasing your market exposure while keeping an eye on global risks.</p>
      `,
      imageUrl: '/images/investor-sentiment.jpg',
      dataUrl: 'https://api.example.com/investor-sentiment',
    },
    {
      id: '8',
      title: 'Market Predictions',
      shortDescription: `
        <strong>Overview:</strong> Predictions for the Indian stock market in the coming weeks.
        <ul>
          <li>Positive outlook for large-cap stocks</li>
          <li>Continued volatility expected</li>
        </ul>
      `,
      longDescription: `
        <h2>Market Predictions</h2>
        <p>This section provides a forecast for the Indian stock market, based on current trends, economic indicators, and market sentiment.</p>
        <h3>Short-Term Predictions:</h3>
        <ul>
          <li><strong>Large-Cap Stocks:</strong> Expected to continue performing well, driven by strong earnings and positive investor sentiment. Sectors like IT, Pharma, and Financials are likely to lead the rally.</li>
          <li><strong>Volatility:</strong> Market volatility is expected to persist due to global uncertainties, including potential interest rate hikes in the US and geopolitical tensions.</li>
          <li><strong>Mid and Small Caps:</strong> These segments may face higher volatility, making them suitable for risk-tolerant investors. However, select stocks with strong fundamentals could outperform.</li>
        </ul>
        <h3>Actionable Insights:</h3>
        <p>Focus on large-cap stocks for stability, while selectively adding mid and small-cap stocks with strong growth potential. Consider using a diversified approach to navigate market volatility.</p>
        <h3>Forecast:</h3>
        <p>The market is expected to show moderate gains in the next month, with potential for short-term corrections. Investors should remain cautious but optimistic, focusing on sectors with strong growth prospects.</p>
      `,
      imageUrl: '/images/market-predictions.jpg',
      dataUrl: 'https://api.example.com/market-predictions',
    },
    {
      id: '9',
      title: 'Global Market Influence',
      shortDescription: `
        <strong>Overview:</strong> Impact of global markets on the Indian stock market.
        <ul>
          <li>US market trends</li>
          <li>China's economic policies</li>
        </ul>
      `,
      longDescription: `
        <h2>Global Influence on Indian Market</h2>
        <p>This section examines the influence of global markets on the Indian stock market, highlighting key trends and policies from major economies that are impacting Indian equities.</p>
        <h3>Global Influences:</h3>
        <ul>
          <li><strong>US Market:</strong> The US Federal Reserve's monetary policy, particularly interest rate decisions, significantly impacts global stocks, including the Indian market. Recent indications of potential rate hikes have led to increased volatility.</li>
          <li><strong>China:</strong> China's economic policies, including regulatory crackdowns and trade policies, have a direct impact on Asian markets. The recent slowdown in China's economy has raised concerns among investors globally.</li>
          <li><strong>European Union:</strong> The economic recovery in the EU, driven by stimulus measures and vaccine rollouts, has contributed to positive sentiment in global markets. This recovery has had a spillover effect on Indian markets, particularly in sectors like IT and Pharma that have significant exposure to Europe.</li>
        </ul>
        <h3>Actionable Insights:</h3>
        <p>Investors should monitor global market developments closely, as they can have a direct impact on Indian stocks. Consider diversifying your portfolio to include assets that may benefit from global trends.</p>
        <h3>Forecast:</h3>
        <p>Global markets are likely to remain a key driver of Indian market performance in the coming weeks. Investors should stay informed and be prepared to adjust their portfolios in response to global developments.</p>
      `,
      imageUrl: '/images/global-influence.jpg',
      dataUrl: 'https://api.example.com/global-influence',
    },
    {
      id: '10',
      title: 'Technical Analysis',
      shortDescription: `
        <strong>Overview:</strong> Technical analysis of the Indian stock market.
        <ul>
          <li>Support and resistance levels</li>
          <li>Key indicators and trends</li>
        </ul>
      `,
      longDescription: `
        <h2>Technical Analysis Report</h2>
        <p>This section provides a technical analysis of the Indian stock market, focusing on key indicators and chart patterns that are influencing current market movements.</p>
        <h3>Key Technicals:</h3>
        <ul>
          <li><strong>Support Levels:</strong> Nifty 50 has strong support at 17,500, which has held firm during recent market corrections. If this level is breached, it could signal further downside.</li>
          <li><strong>Resistance Levels:</strong> Resistance at 18,000 points for Nifty 50 has been tested multiple times, indicating a potential breakout if market sentiment remains positive.</li>
          <li><strong>Moving Averages:</strong> The 50-day and 200-day moving averages are showing bullish trends, suggesting continued upward momentum in the market.</li>
          <li><strong>RSI and MACD:</strong> The Relative Strength Index (RSI) is in a neutral zone, indicating neither overbought nor oversold conditions. The Moving Average Convergence Divergence (MACD) is showing a bullish crossover, signaling potential buying opportunities.</li>
        </ul>
        <h3>Actionable Insights:</h3>
        <p>Traders should look for buying opportunities on pullbacks to support levels, while being cautious near resistance levels. The current technical indicators suggest a potential for further gains, but with a need for careful monitoring of key levels.</p>
        <h3>Forecast:</h3>
        <p>The technical outlook for the next week suggests a positive trend, with potential for further gains if key resistance levels are breached. Traders should stay vigilant and consider using stop-loss orders to manage risk.</p>
      `,
      imageUrl: '/images/technical-analysis.jpg',
      dataUrl: 'https://api.example.com/technical-analysis',
    }
  ];
  